import React from "react";
import { useTranslation } from "react-i18next";
import Mail from "../component/mail"

import Banner from "../assets/product-banner.jpeg";
const Product = (props) => {
    const { t } = useTranslation();

    return (
        <div className="flex flex-col justify-start">
            <div
                className="w-full bg-cover h-[400px] sm:h-[600px] bg-center relative"
                style={{ backgroundImage: `url(${Banner})` }}
            >
                <div className="text-white text-3xl sm:text-6xl font-bold absolute left-[10%] top-[50%]">
                    {t("product_title")}
                </div>
            </div>
            <div className="bg-[#f3f3f3] text-[#666] text-lg sm:text-2xl text-left py-8 px-16">
                {t("product_desc")}
            </div>
            <div className="text-left text-[#666] py-4 px-16">
                <div className="text-xl sm:text-3xl pt-6 pb-2 border-b-2 border-[#FB9966] ">{t("product_q")}</div>
                <div className="px-4">
                    <ul className=" list-outside list-decimal">
                        <li className="py-2">
                            <span className="font-bold leading-10">
                                {t("product_a1_title")}
                            </span>
                            {t("product_a1")}
                        </li>
                        <li className="py-2">
                            <span className="font-bold leading-10">
                                {t("product_a2_title")}
                            </span>
                            {t("product_a2")}
                        </li>
                        <li className="py-2">
                            <span className="font-bold leading-10">
                                {t("product_a3_title")}
                            </span>
                            {t("product_a3")}
                        </li>
                        <li className="py-2">
                            <span className="font-bold leading-10">
                                {t("product_a4_title")}
                            </span>
                            {t("product_a4")}
                        </li>
                    </ul>
                </div>
            </div>

            <div className="bg-[#f3f3f3] text-[#666] text-left text-2xl py-12 px-16">
                {t("product_a5")}
            </div>
            <Mail/>
            
        </div>
    );
};

export default Product;
