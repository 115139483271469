import { useTranslation } from "react-i18next";
import React, { useState, useRef } from "react";
import VisibilitySensor from "react-visibility-sensor";
import anime from "animejs/lib/anime.es.js";
import Mail from "../component/mail";
import Banner from "../assets/about-banner.jpeg";
import Team_cn from "../assets/about-team.png";
import Team_en from "../assets/team-en.png";
const About = (props) => {
    const { t } = useTranslation();
    const [titleFlag, setTitleFlag] = useState(false);
    const [bannerFlag, setBannerFlag] = useState(false);
    const [teamFlag, setTeamFlag] = useState(false);
    const [currentLang, setCurrentLang] = useState(
        window.localStorage.getItem("lang")
    );
    const titleRef = useRef("");
    const bannerRef = useRef("");
    const teamRef = useRef("");
    const showTitle = (isVisible) => {
        if (isVisible && !titleFlag) {
            setTitleFlag(true);
            anime({
                targets: titleRef.current,
                easing: "linear",
                translateY: "-50px",
                opacity: [0, 1],
            });
        }
    };

    const showBanner = (isVisible) => {
        if (isVisible && !bannerFlag) {
            setBannerFlag(true);
            anime({
                targets: bannerRef.current,
                easing: "linear",
                translateY: "-50px",
                opacity: [0, 1],
            });
        }
    };

    const showTeam = (isVisible) => {
        if (isVisible && !teamFlag) {
            setTeamFlag(true);
            anime({
                targets: teamRef.current,
                easing: "linear",
                translateY: "-50px",
                opacity: [0, 1],
            });
        }
    };
    return (
        <div className="flex flex-col">
            <VisibilitySensor onChange={showTitle}>
                <div
                    ref={titleRef}
                    className="p-16 opacity-5 mt-[50px] text-center"
                >
                    <p className=" text-4xl">{t("about_title")}</p>
                    <p className="text-[#666] mt-4">{t("about_desc")}</p>
                </div>
            </VisibilitySensor>
            <div className="flex">
                <div
                    className="hidden basis-[30%] bg-cover bg-no-repeat lg:block"
                    style={{ backgroundImage: `url(${Banner})` }}
                >
                </div>
                <VisibilitySensor onChange={showBanner} partialVisibility={true}>
                    <div className="px-8 py-[70px] md:px-20 md:py-[140px] text-white text-left bg-black basis-[100%] lg:basis-[70%]">
                        <div ref={bannerRef} className="opacity-5 mt-[50px]">
                            <p className="text-[34px]">
                                {t("about_banner_title")}
                            </p>
                            <p className="mt-12 mb-6">
                                {t("about_banner_desc1")}
                            </p>
                            <p>{t("about_banner_desc2")}</p>
                        </div>
                    </div>
                </VisibilitySensor>
            </div>
            <VisibilitySensor onChange={showTeam} partialVisibility={true}>
                <div
                    ref={teamRef}
                    className=" mt-[50px] opacity-5 flex flex-wrap"
                >
                    <div className="py-12 px-8 text-left w-full md:basis-2/5">
                        <p className="text-[30px]">
                            {t("about_concept_title")}
                        </p>
                        <p className="text-[#666] mt-6">
                            {t("about_concept_desc")}
                        </p>
                        <div className="mt-12">
                            {currentLang == "en" ? (
                                <img src={Team_en} />
                            ) : (
                                <img src={Team_cn} />
                            )}
                        </div>
                    </div>
                    <div className="p-12 text-left md:basis-3/5">
                        <div className="py-6 border-y border-y-[#4200ff]">
                            <p className="text-[17px] md:text-2xl">
                                {t("about_main_title1")}
                            </p>
                            <p className="text-[#666] text-[14px] md:text-[15px] mt-4">
                                {t("about_main_desc1")}
                            </p>
                        </div>
                        <div className="py-6 border-b border-b-[#4200ff]">
                            <p className="text-[17px] md:text-2xl">
                                {t("about_main_title2")}
                            </p>
                            <p className="text-[#666] text-[14px] md:text-[15px] mt-4">
                                {t("about_main_desc2")}
                            </p>
                        </div>
                        <div className="py-6 border-b border-b-[#4200ff]">
                            <p className="text-[17px] md:text-2xl">
                                {t("about_main_title3")}
                            </p>
                            <p className="text-[#666] text-[14px] md:text-[15px] mt-4">
                                {t("about_main_desc3")}
                            </p>
                        </div>
                    </div>
                </div>
            </VisibilitySensor>
            <Mail />
        </div>
    );
};

export default About;
