import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./layout/default";
import RouterList from "./router/router.js";
import Index from "./pages/index"
import "./App.css";
function App() {
    const [alertMessage, setAlertMessage] = useState({
        message: "",
        type: "error",
        changePage: false,
    });

    const formatAlert = (msg, type = "error", changePage = false) => {
        setAlertMessage({ message: msg, type: type, changePage: changePage });
    };

    return (
        <div className="App">
            <BrowserRouter>
                <Layout>
                    <Routes>
                        {RouterList.map((page, index) => (
                            <Route
                                key={page.path}
                                path={`/${page.path}`}
                                element={<page.components/>}
                            ></Route>
                        ))}
                    </Routes>
                </Layout>
            </BrowserRouter>
        </div>
    );
}

export default App;
