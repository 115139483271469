let ruleConfig = {
    cn: `<h1>隐私权条款<br></h1>
    海南玖方科技有限公司，非常重视您的隐私权，为了让您能够安心使用玖方的各项服务与资讯，特此向您说明玖方的隐私权保护政策，以保障您的权益。<br>
    <br>
    海南玖方科技有限公司(以下简称玖方)，非常重视您的隐私权，为了让您能够安心使用玖方的各项服务与资讯，特此向您说明玖方的隐私权保护政策，以保障您的权益，请您详阅下列内容：<br>
    <br>
    <h1>一、隐私权保护政策的适用范围<br></h1>
    隐私权保护政策内容，包括玖方如何处当您造访玖方或使用玖方所提供之功能服务时，我们将视该服务功能性质，请您同意提供必要的个人资料，并在该特定目的范围内处理及利用您的个人资料以及您使用网站服务时收集到的个人识别资料，并且您得随时撤回您的同意。隐私权保护政策不适用于玖方以外的相关连结网站，也不适用于非玖方所委托或参与管理的人员。<br>
    <br>
    <h1>二、 个人资料的搜集、处理及利用方式<br></h1>
    当您造访玖方或使用玖方所提供之功能服务时，我们将视该服务功能性质，请您提供必要的个人资料，并在该特定目的范围内处理及利用您的个人资料。<br>
    <br>
    当您使用联络我们、活动报名等相关服务时，我们将会搜集您的个人相关资讯，例如您的姓名、e-mail、公司名称、职称、联络电话等资料，以让我们能够辨识您的身份。<br>
    当您向本站提出问题或意见时，本站会搜集您所反映的内容及相关资讯，以做为与您联系和改进本站服务的参考。<br>
    于一般浏览时，伺服器会自行记录相关行径，包括您使用连线设备的IP位址、使用时间、使用的浏览器、浏览及点选资料记录、Cookie等，相关资料仅会用来提供您更适性化的服务，并改善本站系统的效能与体验。<br>
    若您不愿接受Cookie的写入，您可在您使用的浏览器功能项中设定隐私权等级为高，即可拒绝Cookie的写入，但可能会导致网站某些功能无法正常执行 。<br>
    为提供精确的服务，我们会将收集的问卷调查内容进行统计与分析，分析结果之统计数据或说明文字呈现，除供内部研究外，我们会视需要公布统计数据及说明文字，但不涉及得识别个人之资料。<br>
    您同意玖方将搜集到的资讯作为以下之利用：<br>
    玖方将以电子邮件、电话、简讯或书面的方式向您提供最新的产品及服务资讯、优惠活动或课程讯息，若参加活动或购买产品及服务等会将资料交与金流公司或金融机构以完成本服务。<br>
    作为接受各网站各项服务与资讯之用，包括但不限于市场调查、问卷或玖方受企业委托代为发送之各类电子报等资讯。<br>
    其他另经本人以书面、电子邮件、传真、网页点选按钮同意之特定目的之利用。<br>
    玖方将基于共同行销之目的，在适当且必要的范围内将会员及客户资料予以揭露、转介或交换予玖方所属其他网站及友站、关系企业及业务合作伙伴交互运用，但资料应妥善运用及管理，且玖方所属其他网站及友站、关系企业及业务合作伙伴会确保您所提供之所有资料皆受到“隐私权”政策之严格保护。<br>
    玖方绝不会提供、交换、出租或出售任何您的个人资料给其他个人、团体、私人企业或公务机关，但有法律依据或合约义务者，不在此限。<br>
    若有以下情形，玖方会适度揭露相关个人资料：基于法院命令或法律程序要求，玖方负有揭露义务时，玖方会将相关个人资料揭露给有关机关，以及使用者违反资讯安全、非法活动、涉嫌诈欺、对人身安全有潜在威胁的状况、对玖方服务条款的违反，或法律另有规定时。<br>
    <br>
    <h1>三、 网站对外的相关连结<br></h1>
    玖方的网页提供其他网站的网路连结，您也可经由玖方所提供的连结，点选进入其他网站。但该连结网站不适用玖方的隐私权保护政策，您必须参考该连结网站中的隐私权保护政策。<br>
    <br>
    <h1>四、 同意<br></h1>
    您同意本隐私权政策之条款，也同意玖方基于此处所列目的搜集和处理您的个人资讯。您的个人资讯可能会在搜集所在国家和台湾进行处理。玖方科技将致力于遵守所有客户隐私权的适用法律。您的个人资讯至少会根据本隐私权政策与台湾法律处理。提醒您，台湾的法律可能会比您所在国家的法律严苛或宽松。<br>
    <br>
    <h1>五、 隐私权保护政策之修正<br></h1>
    为因应时势变迁或法令修正等事由，本网站有权于任何时间修改或变更本政策之内容，您应经常查看以了解您当前的权利及义务，但本网站不会在未取得您明确同意的情况下，迳自缩减本政策赋予您的权利。如果本政策有重大异动，本网站会提供更明显的通知 (例如以电子邮件通知政策异动)。您须同意接受本政策之修改及变更，才能在本网站修改或变更本政策后仍继续使用本服务。<br>
    <br>
    <h1>六、 您的权利<br></h1>
    我们尊重您提供的个人资料，关于您所提供予我们的个人资料，您可以透过联系我们的服务信箱<a href="mailto:admin@hainan-jufang.site">admin@hainan-jufang.site</a>行使下列权利:<br>
    <br>
    查询或请求阅览您的个人资料。<br>
    <br>
    请求提供给您的个人资料复制本。<br>
    <br>
    请求补充或更正您的个人资料。<br>
    <br>
    请求停止搜集、处理或利用您的个人资料。<br>
    <br>
    请求删除您的个人资料。<br>
    <br>
    <h1>七、联络我们<br></h1>
    若您对于本政策或本网站有任何问题或建议，欢迎来信至我们的服务信箱 <a href="mailto:admin@hainan-jufang.site">admin@hainan-jufang.site</a>。`,
    en: `<h1>Privacy Policy<br></h1>
    We, Hainan Jufang Technology Co., Ltd., believe your privacy is important. To fulfill our commitment to protecting your privacy, we hereby assist you in understanding what information we may collect from you and how we would use such information.<br>
    <br>
    <h1>I. Scope of Terms<br></h1>
    When you visit the website or use our services hereof, you voluntarily provide necessary information to us and you may contact us to delete such information at any time. This Privacy Policy shall not be used for other purposes other than this website.<br>
    <br>
    <h1>II. How we collect, manage, and use the information<br></h1>
    We will process and use your personal information only within the following scope of specific purposes as you agreed upon:<br>
    <br>
    When you submit an inquiry or register an event hosted by us, you will be required to provide the information including name, e-mail address, company names, titles and mobile for registration.<br>
    When you submit surveys, questions or any other forms, we will use the information you provided for further contact and as one of the references to modify our website.<br>
    When you visit this website, we will use your Cookies to track and collect the information including IP addresses, browsing time, types of browsers and history. All information will be used for improving user experiences only.<br>
    You may disable Cookies if you do not agree with the Privacy Policy. Please set the privacy settings of your browsers to the highest level to block the Cookies and please note that some of the services or functions will no longer be available once Cookies are disabled.<br>
    To provide you with personalized experience, we will further analyze the collected information to engage in internal analysis and improvements and the collected data may be published in the form of texts if necessary. No personal information will be exposed.<br>
    You agree to allow us to use the collected information for the following purposes:<br>
    We may send you the information on the latest services, discounts or events in the forms of emails, phones, texts or newsletters. When you purchase our services, we will provide your information to related third-party companies or banks for billing purposes.<br>
    We will use the information for the following activities, including but not limited to  marketing analysis, surveys or newsletters.<br>
    Any forms of agreement, including texts, e-mails, faxes, submissions on the webpage, whereof you agree to provide personal information with us for certain purposes.<br>
    We will provide, transform or exchange your personal information with any related websites, including this website and the websites of our Partners and Alliance members for the purposes of marketing. We and our partners and alliance will ensure your personal information is protected.<br>
    Unless otherwise specified in this Privacy Policy or agreed by you, your information will not be provided to third-party organizations.
    <br>
    <br>
    <h1> III. Exclusion of Privacy Policy<br></h1>
    This Privacy Policy does not apply to the external links on Rizhao’s website.<br>
    <br>
    <h1>IIII. Consent<br></h1>
    When you use our services, you voluntarily agree to provide us with your personal information which shall be used in Taiwan and local regions only. We will be committed to protecting your privacy, and all data collected and used shall be compliant with related laws and regulations in Taiwan.<br>
    <br>
    <h1>V. Changes of Privacy Policy<br></h1>
    We reserve the right to change or modify this Privacy Policy. If there is any change in this Privacy Policy, we will inform you (via e-mails or other forms) or publicly declare the latest version on our website. You may disagree with the latest version of Privacy Policy, but our services or the functions of this website might no longer be available for you.<br>
    <br>
    <h1>VI. Your Rights<br></h1>
    Regarding your personal information provided by you to us, you may submit the following requests to us by emailing to admin@hainan-jufang.site:<br>
    Request for searching or reading such personal information;<br>
    Request for duplicate of such personal information;<br>
    Request for supplement or correction of such personal information;<br>
    Request for suspension from collection, process or use of such personal information;<br>
    Request for deletion of such personal information.<br>
    <br>
    <h1>VII. Contact Information<br></h1>
    If there are any queries on the use of Cookies or this Privacy Policy, please contact us by emailing to <a href="mailto:admin@hainan-jufang.site">admin@hainan-jufang.site</a>`
}
export default ruleConfig;