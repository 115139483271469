import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { ArrowCircleRightIcon } from "@heroicons/react/outline";
import VisibilitySensor from "react-visibility-sensor";
import anime from "animejs/lib/anime.es.js";
import Mail from "../component/mail";

import Service1 from "../assets/service-cover-9.jpg";
import Service2 from "../assets/service-cover-10.jpg";
import Service3 from "../assets/service-cover-11.jpg";
import Service4 from "../assets/service-cover-12.jpg";
import Service5 from "../assets/service-cover-13.jpg";
import Service6 from "../assets/service-cover-14.jpg";
import Service7 from "../assets/service-cover-15.jpg";
import Service8 from "../assets/service-cover-16.jpg";
import SquareTriangle from "../assets/icon-circle-square-triangle.png";
import Circle from "../assets/icon-circle.png";
import Cube from "../assets/icon-cube-line.png";
import CCube from "../assets/icon-cube.png";
import Diamond from "../assets/icon-diamond.png";
import Scube from "../assets/icon-square-cube.png";
import Square from "../assets/icon-square.png";
import Triangle from "../assets/icon-triangle.png";

const Index = (props) => {
    const { t, i18n } = useTranslation();
    const [numFlag, setNumFlag] = useState(false);
    const [secondFlag, setSecondFlag] = useState(false);
    const [proFlag, setProFlag] = useState(false);
    const [proItemFlag, setProItemFlag] = useState(false);
    const expAll = useRef(null);
    const exp1 = useRef(null);
    const exp2 = useRef(null);
    const exp3 = useRef(null);
    const exp4 = useRef(null);
    const pro1 = useRef(null);
    const pro2 = useRef(null);
    const pro3 = useRef(null);
    const pro4 = useRef(null);
    const pro5 = useRef(null);
    const pro6 = useRef(null);
    const pro7 = useRef(null);
    const pro8 = useRef(null);
    const secondRef = useRef(null);
    const proRef = useRef(null);
    const services = [
        {
            img: Service1,
            title: t("service_cover_title_1"),
            desc: t("service_cover-des_1"),
            ref: pro1,
        },
        {
            img: Service2,
            title: t("service_cover_title_2"),
            desc: t("service_cover-des_2"),
            ref: pro2,
        },
        {
            img: Service3,
            title: t("service_cover_title_3"),
            desc: t("service_cover-des_3"),
            ref: pro3,
        },
        {
            img: Service4,
            title: t("service_cover_title_4"),
            desc: t("service_cover-des_4"),
            ref: pro4,
        },
        {
            img: Service5,
            title: t("service_cover_title_5"),
            desc: t("service_cover-des_5"),
            ref: pro5,
        },
        {
            img: Service6,
            title: t("service_cover_title_6"),
            desc: t("service_cover-des_6"),
            ref: pro6,
        },
        {
            img: Service7,
            title: t("service_cover_title_7"),
            desc: t("service_cover-des_7"),
            ref: pro7,
        },
        {
            img: Service8,
            title: t("service_cover_title_8"),
            desc: t("service_cover-des_8"),
            ref: pro8,
        },
    ];
    const solutions = [
        {
            img: CCube,
            title: t("sol_title1"),
            desc: t("sol_desc1"),
        },
        {
            img: SquareTriangle,
            title: t("sol_title2"),
            desc: t("sol_desc2"),
        },
        {
            img: Diamond,
            title: t("sol_title3"),
            desc: t("sol_desc3"),
        },
        {
            img: Square,
            title: t("sol_title4"),
            desc: t("sol_desc4"),
        },
        {
            img: Circle,
            title: t("sol_title5"),
            desc: t("sol_desc5"),
        },
        {
            img: Cube,
            title: t("sol_title6"),
            desc: t("sol_desc6"),
        },
        {
            img: Triangle,
            title: t("sol_title7"),
            desc: t("sol_desc7"),
        },
        {
            img: Scube,
            title: t("sol_title8"),
            desc: t("sol_desc8"),
        },
    ];
    const showElement = (isVisible) => {
        if (isVisible && !secondFlag) {
            setSecondFlag(true);
            anime({
                targets: secondRef.current,
                easing: "linear",
                translateY: "-80px",
                opacity: [0, 1],
            });
        }
    };

    const showProElement = (isVisible) => {
        if (isVisible && !proFlag) {
            setProFlag(true);
            anime({
                targets: proRef.current,
                easing: "linear",
                translateY: "-80px",
                opacity: [0, 1],
            });

            services.forEach((item,index)=>{
                console.log(item)
                anime({
                    targets: item.ref.current,
                    easing: "linear",
                    translateY: "-80px",
                    opacity: [0, 1],
                    delay:300*index
                });
            })
        }
    };

    const goMail = () => {
        window.location = "mailto:admin@hainan-jufang.site";
    };

    const onChange = (isVisible) => {
        if (isVisible && !numFlag) {
            setNumFlag(true);
            anime({
                targets: expAll.current,
                easing: "linear",
                opacity: [0, 1],
            });
            anime({
                targets: exp1.current,
                innerHTML: [0, 3],
                easing: "linear",
                round: 1,
            });
            anime({
                targets: exp2.current,
                innerHTML: [0, 10],
                easing: "linear",
                round: 1,
            });
            anime({
                targets: exp3.current,
                innerHTML: [0, 1000],
                easing: "linear",
                round: 1,
            });
            anime({
                targets: exp4.current,
                innerHTML: [0, 2000],
                easing: "linear",
                round: 1,
            });
        }
    };
    useEffect(() => {}, []);
    return (
        <div>
            <div className="index-banner-bg flex w-full relative">
                <div className="flex-1 text-left hidden pl-12 md:block">
                    <p className="index-header-font-size pl-4 mt-36 leading-normal sm:text-4xl">
                        {t("index_title")}
                    </p>
                    <p className="text-[#666] text-xl mt-8 pl-6 pr-8">
                        {t("index_description")}
                    </p>
                    <div onClick={goMail} className="flex items-center absolute pl-4 bottom-10 cursor-pointer text-[#4200ff]">
                        <ArrowCircleRightIcon className="h-7" />
                        <span className="ml-3 text-black">
                            {t("go_future")}
                        </span>
                    </div>
                </div>

                <div className="flex-1 index-banner"></div>
                <div className="absolute w-[70vw] h-[80%] p-4 text-left left-0 right-0 top-0 bottom-0 m-auto bg-white bg-opacity-90 shadow-md shadow-black/10 md:hidden">
                    <p className="text-2xl leading-normal">
                        {t("index_title")}
                    </p>
                    <p className="text-[#666] text-lg mt-3">{t("index_description")}</p>
                    <div onClick={goMail} className="flex items-center absolute left-4 bottom-10 cursor-pointer text-[#4200ff]">
                        <ArrowCircleRightIcon className="h-7" />
                        <span className="ml-3 text-black">
                            {t("go_future")}
                        </span>
                    </div>
                </div>
            </div>
            <VisibilitySensor onChange={onChange}>
                <div
                    ref={expAll}
                    className="flex opacity-0 justify-around flex-wrap px-4 py-16"
                >
                    <div className="p-2 w-[45vw] sm:w-[22vw]">
                        <div className="flex justify-center">
                            <p ref={exp1} className="text-6xl font-medium">
                                3
                            </p>
                            <span className="ml-2 text-2xl self-end text-[#666]">
                                +
                            </span>
                        </div>
                        <p className="text-[#666] mt-4">{t("exp1")}</p>
                    </div>
                    <div className="p-2 w-[45vw] sm:w-[22vw]">
                        <div className="flex justify-center">
                            <p ref={exp2} className="text-6xl font-medium">
                                1000
                            </p>
                            <span className="ml-2 text-2xl self-end text-[#666]">
                                +
                            </span>
                        </div>
                        <p className="text-[#666] mt-4">{t("exp2")}</p>
                    </div>
                    <div className="p-2 w-[45vw] sm:w-[22vw]">
                        <div className="flex justify-center">
                            <p ref={exp3} className="text-6xl font-medium">
                                1000
                            </p>
                            <span className="ml-2 text-2xl self-end text-[#666]">
                                +
                            </span>
                        </div>

                        <p className="text-[#666] mt-4">{t("exp3")}</p>
                    </div>
                    <div className="p-2 w-[45vw] sm:w-[22vw]">
                        <div className="flex justify-center">
                            <p ref={exp4} className="text-6xl font-medium">
                                2000
                            </p>
                            <span className="ml-2 text-2xl self-end text-[#666]">
                                +
                            </span>
                        </div>
                        <p className="text-[#666] mt-4">{t("exp4")}</p>
                    </div>
                </div>
            </VisibilitySensor>
            <VisibilitySensor onChange={showElement}>
                <div className="flex w-full flex-wrap-reverse relative">
                    <div className="index-banner-bg-second hidden text-left w-1/6 h-full absolute top-full sm:block sm:static sm:w-1/3 sm:h-[50vh]"></div>

                    <div
                        ref={secondRef}
                        className="index-banner opacity-0 py-4 px-10 flex justify-center items-center mt-[80px] min-w-['550px'] w-full flex-col text-left sm:w-2/3"
                    >
                        <div>
                            <p className="index-banner-second-font-size leading-normal">
                                {t("index_second_title")}
                            </p>
                            <p className="text-[#666] mt-3">
                                {t("index_second_description")}
                            </p>
                        </div>
                    </div>
                </div>
            </VisibilitySensor>
            <div className="flex flex-col bg-black text-white w-full">
                <VisibilitySensor onChange={showProElement}>
                    <div
                        ref={proRef}
                        className="flex opacity-0 flex-wrap mt-[80px] text-left p-12"
                    >
                        <div className="text-3xl w-full mb-4 sm:w-[33vw]">
                            <p>{t("service_title1")}</p>
                            <p>{t("service_title2")}</p>
                        </div>
                        <div className="text-lg font-light w-full sm:w-[50vw] sm:pl-8">
                            {t("service_description")}
                        </div>
                    </div>
                </VisibilitySensor>
                
                <div className="flex flex-row justify-around items-stretch flex-wrap mx-18">
                    {services.map((item, index) => (
                        <div
                            ref={item.ref}
                            key={index}
                            className="flex opacity-20 flex-col mt-[70px] basis-[80%] shrink-0 grow-0 bg-cover  box-content mb-8 sm:basis-[45%]  lg:basis-[22%]"
                            style={{ backgroundImage: `url(${item.img})` }}
                        >
                            <div className="hidden sm:block">
                                <img className="w-full" src={item.img} />
                            </div>
                            <div className="text-left p-8 h-full bg-cover bg-gradient-to-r from-white to-white/30 sm:bg-white">
                                <p className="text-xl text-black mb-2">
                                    {item.title}
                                </p>
                                <p className="text-[#666]">{item.desc}</p>
                            </div>
                        </div>
                    ))}
                </div>

            </div>

            <div className="flex flex-col items-center bg-[#f8fafb] pb-8">
                <div className="text-left w-[80%] sm:w-[89%]">
                    <p className="text-2xl mt-12">{t("solution_title1")}</p>
                    <p className="text-2xl mb-8">{t("solution_title2")}</p>
                    <p className="text-md font-light mb-16">
                        {t("solution_description")}
                    </p>
                </div>
                <div className="flex flex-row justify-center items-stretch flex-wrap bg-[#f8fafb]">
                    {solutions.map((item, index) => (
                        <div
                            key={index}
                            className="mr-[1px] mb-[1px] flex flex-col basis-[80%] shrink-0 grow-0 box-content sm:basis-[45%]  lg:basis-[22%]"
                        >
                            <div className="text-left p-8 h-full bg-white">
                                <div className="mb-4">
                                    <img src={item.img} />
                                </div>
                                <p className="text-xl text-black mb-2">
                                    {item.title}
                                </p>
                                <p className="text-[#666] h">{item.desc}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <Mail />
        </div>
    );
};

export default Index;
