import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ruleConfig  from "../assets/privecy";
const Privacy = (props) => {
  const [currentLang, setCurrentLang] = useState(
    window.localStorage.getItem("lang")??'cn'
);
  return (
    <div className="privacy text-left p-8 sm:p-24 text-[#666]" dangerouslySetInnerHTML={{__html: ruleConfig[currentLang]}}></div>
  );
};

export default Privacy;
