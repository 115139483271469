import Index from "../pages/index"
import About from "../pages/about"
import Product from "../pages/product"
import Privacy from "../pages/privacy"
const RouterList = [{
    path: "",
    components: Index,
}, {
    path: "about",
    components: About,
}, {
    path: "product",
    components: Product,
}, {
    path: "privacy",
    components: Privacy,
}, ]

export default RouterList