/* This example requires Tailwind CSS v2.0+ */
import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { GlobeAltIcon, MenuIcon, XIcon } from "@heroicons/react/outline";
import { useTranslation } from "react-i18next";
import { Link, useNavigate} from "react-router-dom";
import Logo from "../assets/logo/Logo.png";
const langNavigation = [
    { name: "简体中文", value: "cn" },
    { name: "English", value: "en" },
];

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function Example() {
    const { t, i18n } = useTranslation();
    const [currentLang, setCurrentLang] = useState(
        window.localStorage.getItem("lang")
    );
    let location = useNavigate()
    const navigation = [
        { name: t("prod_service"), href: "/product", current: false },
        { name: t("about_os"), href: "/about", about: false },
        { name: t("privacy_policy"), href: "/privacy", current: false },
    ];
    const changeLanguage = () => {
        let changeLan = currentLang == "en" ? "cn" : "en";
        // i18n.changeLanguage(changeLan);
        // setCurrentLang(changeLan);
        window.localStorage.setItem("lang", changeLan);
        window.location.reload()
    };

    const changePage =(path)=>{
        location(path)
    }
    useEffect(() => {
        i18n.changeLanguage(currentLang);
    }, []);
    return (
        <>
            <div className="min-h-full shadow-lg  shadow-black/10">
                <Disclosure as="nav" className="">
                    {({ open }) => (
                        <>
                            <div className="w-full mx-auto fixed z-10  bg-white px-4 sm:px-6 lg:px-8">
                                <div className="flex items-center justify-between h-16">
                                    <div className="flex items-center">
                                        <div className="flex-shrink-0">
                                            <Link to="/">
                                                <img
                                                    className="h-12 w-auto"
                                                    src={Logo}
                                                    alt="Workflow"
                                                />
                                            </Link>
                                        </div>
                                        <div className="hidden md:block">
                                            <div className="ml-10 flex items-baseline space-x-4">
                                                {navigation.map((item) => (
                                                    <Link
                                                        key={item.name}
                                                        className={classNames(
                                                            item.current
                                                                ? "text-[#4200ff]"
                                                                : "text-[#666]",
                                                            "px-3 py-2 ease-in duration-300 text-sm font-medium hover:text-[#4200ff]"
                                                        )}
                                                        to={item.href}
                                                    >
                                                        {item.name}
                                                    </Link>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="hidden md:block">
                                        <div className="ml-4 flex items-center md:ml-6">
                                            {/* Profile dropdown */}
                                            <Menu
                                                as="div"
                                                className="ml-3 relative"
                                            >
                                                <div>
                                                    <Menu.Button className="max-w-xs rounded-full flex items-center text-sm ">
                                                        <span className="sr-only">
                                                            Open user menu
                                                        </span>
                                                        <GlobeAltIcon
                                                            className="h-6 w-6 text-[#666]"
                                                            aria-hidden="true"
                                                        />
                                                    </Menu.Button>
                                                </div>
                                                <Transition
                                                    as={Fragment}
                                                    enter="transition ease-out duration-100"
                                                    enterFrom="transform opacity-0 scale-95"
                                                    enterTo="transform opacity-100 scale-100"
                                                    leave="transition ease-in duration-75"
                                                    leaveFrom="transform opacity-100 scale-100"
                                                    leaveTo="transform opacity-0 scale-95"
                                                >
                                                    <Menu.Items className="origin-top-right z-10 absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                        {langNavigation.map(
                                                            (item) => (
                                                                <Menu.Item
                                                                    key={
                                                                        item.name
                                                                    }
                                                                >
                                                                    {({
                                                                        active,
                                                                    }) => (
                                                                        <a
                                                                            onClick={() => {
                                                                                changeLanguage(
                                                                                    item.value
                                                                                );
                                                                            }}
                                                                            className={classNames(
                                                                                active
                                                                                    ? "bg-gray-100"
                                                                                    : "",
                                                                                "block px-4 py-2 text-sm text-gray-700"
                                                                            )}
                                                                        >
                                                                            {
                                                                                item.name
                                                                            }
                                                                        </a>
                                                                    )}
                                                                </Menu.Item>
                                                            )
                                                        )}
                                                    </Menu.Items>
                                                </Transition>
                                            </Menu>
                                        </div>
                                    </div>
                                    <div className="-mr-2 flex md:hidden">
                                        {/* Mobile menu button */}
                                        <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-[#666666] ">
                                            <span className="sr-only">
                                                Open main menu
                                            </span>
                                            {open ? (
                                                <XIcon
                                                    className="block h-9 w-9"
                                                    aria-hidden="true"
                                                />
                                            ) : (
                                                <MenuIcon
                                                    className="block h-9 w-9"
                                                    aria-hidden="true"
                                                />
                                            )}
                                        </Disclosure.Button>
                                    </div>
                                </div>
                            </div>

                            <Disclosure.Panel className="bg-white z-10 fixed top-[64px] w-full md:hidden">
                                <div className="pt-2 pb-3 text-left space-y-1 sm:px-3">
                                    {navigation.map((item) => (
                                        <Disclosure.Button
                                            key={item.name}
                                            as="a"
                                            // href={item.href}
                                            onClick={()=>{changePage(item.href)}}
                                            className={classNames(
                                                "block px-3 py-3 text-base text-[#666]  border-b border-gray-400"
                                            )}
                                            aria-current={
                                                item.current
                                                    ? "page"
                                                    : undefined
                                            }
                                        >
                                            {item.name}
                                        </Disclosure.Button>
                                    ))}
                                    <a
                                        onClick={() => {
                                            changeLanguage("en");
                                        }}
                                        className={classNames(
                                            " block px-3 py-3 text-base text-[#666]"
                                        )}
                                    >
                                        {currentLang == "en"
                                            ? "简体中文"
                                            : "English"}
                                    </a>
                                </div>
                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>
            </div>
        </>
    );
}
